import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Nevada",
  "subtitle": "-",
  "category": "USA"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "vegas"
    }}>{`Vegas`}</h2>
    <ul>
      <li parentName="ul">{`If you're renting a car, do it at the airport`}</li>
      <li parentName="ul">{`Lyft is slightly cheaper than Uber but both are readily available`}</li>
    </ul>
    <h3 {...{
      "id": "lodging"
    }}>{`Lodging`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/LmVyLSnz6kkZv1YSA"
        }}>{`Vdara Hotel & Spa`}</a>{` - No casino, no smoking and next to ARIA, on the strip`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/iQYE16mRVDCrkoTZ6"
        }}>{`Resort World`}</a>{` - Multiple hotels, off the strip`}</li>
    </ul>
    <h3 {...{
      "id": "gambling"
    }}>{`Gambling`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/kPtzdTTH77qPC2XcA"
        }}>{`Park MGM`}</a>{` is the only non-smoking casino I'm aware of`}</li>
      <li parentName="ul">{`Off strip gambling generally has better odds`}</li>
    </ul>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/7i1jJSyDPuy5RNXN6"
        }}>{`Bacchanal Buffet`}</a>{` - At Caesars Palace`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/i6Aq4pnh5vTHzRUPA"
        }}>{`Sadelle’s Cafe`}</a>{` - At Bellagio; The Salmon tower comes with unlimited bagels and is great for sharing`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.in-n-out.com/"
        }}>{`In-N-Out Burger`}</a></li>
    </ul>
    <h4 {...{
      "id": "downtown"
    }}>{`Downtown`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/7FjUnSHZgPgjNHCH8"
        }}>{`Carson Kitchen`}</a>{` - Very noms`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/dFPxRGcWnKSSfvDb9"
        }}>{`Nacho Daddy`}</a>{` - Chain`}</li>
    </ul>
    <h4 {...{
      "id": "chinatown"
    }}>{`Chinatown`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/Tng7NKi4dMgxbxfV7"
        }}>{`HK Seafood Garden`}</a>{` - Good dim sum`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/HJg86yuvEhxzDCCY7"
        }}>{`Sul & Beans`}</a>{` - Love their bingsu`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/vRbaZYRER6xw51757"
        }}>{`Shanghai Taste`}</a>{` - Soup dumplings`}</li>
    </ul>
    <h4 {...{
      "id": "other"
    }}>{`Other`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/swjiK1PsZpcUjDay8"
        }}>{`Kogi Korean BBQ & Seafood Hot Pot`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://bellagio.mgmresorts.com/en/entertainment/fountains-of-bellagio.html"
        }}>{`Bellagio Foundation Show`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.cirquedusoleil.com/las-vegas-shows"
        }}>{`Cirque de Soleil`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.cirquedusoleil.com/ka"
            }}>{`KA`}</a>{` - Enjoyed it`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/zaUjFy2QxKo9MonK9"
        }}>{`Area15`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://tickets.meowwolf.com/las-vegas/"
            }}>{`Meow Wolf Las Vegas`}</a>{` - Really fun! Interactive scavenger hunt inside an art exhibit; Recommend advance ticketing and story add-on!`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/A3uszMQR3LTc2gDb6"
            }}>{`Asylum Bar + Arcade`}</a></li>
        </ul>
      </li>
    </ul>
    <h4 {...{
      "id": "downtown-1"
    }}>{`Downtown`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/qDAT16WTE1UZMwua6"
        }}>{`Neon Museum`}</a>{` - The museum tour was great but I didn't enjoy the light show`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/M55jVQLzgXELiYez7"
        }}>{`Mob Museum`}</a>{` - Interesting Vegas history`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      